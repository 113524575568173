<template>
  <div id="myCard">
    <div class="nothing-box" v-if="isNothing">您还未绑定银行卡</div>
    <div class="card-box" v-if="!isNothing">
      <div class="top-info">
        <div class="line"></div>
        <p>我的银行卡</p>
        <span>共{{bankNum}}张</span>
      </div>

      <div class="card-item" v-for="(item) in bankList" :key="item.id">
        <div class="card-left">
          <img v-lazy="item.bank_logo" alt />
          <p>{{item.bank_name}}</p>
          <span>**** {{item.card_numbe}}</span>
        </div>

        <div class="card-right" @click="removeCard(item.id, item.card_numbe)">解除绑定</div>
      </div>
    </div>
    <div class="add-box">
      <div class="add-btn" @click="$router.push({path: '/addCard'})">添加银行卡</div>
      <p class="tip">支持储蓄卡/信用卡</p>
    </div>
  </div>
</template>

<script>
import { myBankCard, removeBankCard } from "../api/requist";
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  components: {},
  props: [],
  data() {
    return {
      bankNum: 0,
      isNothing: false,
      bankList: []
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      let formData = new FormData();
      formData.append("merch_id", localStorage.getItem('merch_id'));
      let res = await myBankCard(formData);
      if (res.code === 200) {
        this.bankList = res.data.MerchBankList;
        this.bankNum = res.data.MerchBankCount;
        this.bankNum == 0 ? this.isNothing = true :this.isNothing = false;
      } else {
        this.isNothing = true
      }
    },

    removeCard(id, num) {
      Dialog.confirm({
        confirmButtonColor: "#FF6737",
        confirmButtonText: "暂不解绑",
        cancelButtonText: "仍要解绑",
        cancelButtonColor: "#909399",
        message: `确定要解绑卡（**** ${num}）吗？`
      })
        .catch(() => {
          removeBankCard({ merch_id: 1, bank_id: id }).then(res => {
            Toast(res.message);
            if (res.code === 200) {
              this.bankList = this.bankList.filter(item => {
                if (item.id !== id) {
                  return item;
                }
              });
            }
          });
        })
        .then(() => {
          // on cancel
        });
    }
  }
};
</script>
<style lang="scss" >
 @import '../common/css/theme.scss';
#myCard {
  background: rgba(245, 247, 250, 1);
  min-height: 100vh;

  .card-box {
    margin-bottom: 8.53* 3.75px;
    background: rgba(255, 255, 255, 1);
    padding: 3.2* 3.75px 4.8* 3.75px 0;
    box-sizing: border-box;
    .top-info {
      margin-bottom: 6.4* 3.75px;
      display: flex;
      align-items: center;
      .line {
        margin-right: 1.33* 3.75px;
        border-radius: 4px;
        width: 1* 3.75px;
        height: 2.67* 3.75px;
        background-color: $theme-color;
        box-shadow: 0* 3.75px 1* 3.75px 1* 3.75px rgba(116, 205, 89, 0.3);
      }

      p {
        font-size: 4.27* 3.75px;
        font-weight: bold;
        color: rgba(48, 49, 51, 1);
        margin-right: 1.6* 3.75px;
      }
      span {
        color: rgba(144, 147, 153, 1);
        font-size: 3.2* 3.75px;
      }
    }

    .card-item {
      height: 14.13* 3.75px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(240, 242, 245, 1);
      .card-left {
        display: flex;
        align-items: center;
        img {
          height: 7.73* 3.75px;
          width: 7.73* 3.75px;
          margin-right: 1.6* 3.75px;
        }
        p {
          font-size: 3.73* 3.75px;
          color: rgba(48, 49, 51, 1);
          margin-right: 1.6* 3.75px;
        }

        span {
          font-size: 3.73* 3.75px;
          color: rgba(144, 147, 153, 1);
        }
      }
      .card-right {
        font-size: 3.73* 3.75px;
        color: rgba(192, 196, 204, 1);
      }
    }
  }
  .nothing-box {
    font-size: 3.73* 3.75px;
    font-weight: 400;
    text-align: center;
    line-height: 33.13* 3.75px;
    color: rgba(48, 49, 51, 1);
    height: 33.13* 3.75px;
  }

  .add-box {
    padding: 3.2* 3.75px 4.8* 3.75px 0;
    box-sizing: border-box;
    width: 90.4* 3.75px;
    height: 25.33* 3.75px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0* 3.75px 1* 3.75px 2* 3.75px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 2* 3.75px;
    margin: 0 auto;
    .add-btn {
      width: 80.8* 3.75px;
      height: 10.4* 3.75px;
      background: linear-gradient(
        180deg,
        $wallte-color 0%,
        $theme-color 100%
      );
      border-radius: 1* 3.75px;
      font-size: 4.27* 3.75px;
      line-height: 10.4* 3.75px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      margin-bottom: 3.2* 3.75px;
    }
    .tip {
      font-size: 3.73* 3.75px;
      color: rgba(192, 196, 204, 1);
      text-align: center;
    }
  }
}
</style>
